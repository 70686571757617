import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { QuardServiceService } from './quard-service.service';

@Injectable({
  providedIn: 'root'
})
export class RouteQuardGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router,private qservice:QuardServiceService) { };
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    let isLoggedIn = this.qservice.canActivate();
    console.log(this.qservice.canActivate());
    if (isLoggedIn) {
      return true
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }


}
