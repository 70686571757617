import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteQuardGuard } from './route-quard.guard';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./user-service/user-service.module').then(m => m.UserServiceModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'master',
    loadChildren: () => import('./master/master.module').then(m => m.MasterModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'daf',
    loadChildren: () => import('./daf/daf.module').then(m => m.DafModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'caccountant',
    loadChildren: () => import('./chiefaccountant/chiefaccountant.module').then(m => m.ChiefaccountantModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'accountant',
    loadChildren: () => import('./accountant/accountant.module').then(m => m.AccountantModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'parent',
    loadChildren: () => import('./parent/parent.module').then(m => m.ParentModule),
    canActivate:[RouteQuardGuard]
  },
  {
    path: 'assistant',
    loadChildren: () => import('./assistant/assistant.module').then(m => m.AssistantModule),
    canActivate:[RouteQuardGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
