import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class QuardServiceService {

  constructor(private cookieService: CookieService) { }

  public isLoggedIn = false;
  public canActivate() :boolean{
    let access = sessionStorage.getItem('access');
    if(access=="ON"){
      console.log(access);
      return true;
    }
    return false;
  }



}
